"use strict";

const {
  validate,
  ValidationError
} = require("./validate");

module.exports = {
  validate,
  ValidationError
};